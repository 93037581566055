import { Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MessageConstants } from '../../services/message/message-constants';
import { MessageService } from '../../services/message/message.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  menuOpen: boolean = false;
  currentRoute: string = this.router.url;
  routerSubscription: Subscription | undefined;
  messageSubscription: Subscription | undefined;

  constructor(
    private readonly router: Router,
    private readonly messageService: MessageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url;
        this.menuOpen = false;
      }
    });

    this.messageSubscription = this.messageService
      .onMessage()
      .subscribe((message) => {
        if (message == MessageConstants.openSideMenu) {
          this.menuOpen = true;
        }
      });
  }

  userIsSuperuser(): boolean {
    return this.userService.userIsSuperuser();
  }

  userIsGeneralManager(): boolean {
    return this.userService.userIsGeneralManager();
  }

  userIsAssistantGeneralManager(): boolean {
    return this.userService.userIsAssistantGeneralManager();
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.messageSubscription?.unsubscribe();
  }
}
