import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { News } from '../../models/news';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  apiUrl = `${environment.api.url}api/News`;

  constructor(private http: HttpClient) {}

  get(): Observable<News[]> {
    this.cancelPrevious();

    return this.http
      .get<News[]>(this.apiUrl)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  save(news: News): Observable<News[]> {
    this.cancelPrevious();
    return this.http
      .put<News[]>(this.apiUrl, news)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  delete(): Observable<News[]> {
    this.cancelPrevious();
    return this.http
      .delete<News[]>(this.apiUrl)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  // Cancel previous subscription and create a new one.
  cancelPrevious() {
    if (!this.ngUnsubscribe.observers) {
      this.ngUnsubscribe = new Subject<void>();
    }

    this.ngUnsubscribe.next();
  }

  // Destroy subscription
  unsubscribe() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
