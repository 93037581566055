import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-menu-sub-link',
  templateUrl: './sub-menu-sub-link.component.html',
})
export class SubMenuSubLinkComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() routerLink: string | undefined;
  @Input() currentRoute: string | undefined;

  constructor() {}

  ngOnInit(): void {}
}
