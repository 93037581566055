//https://stackoverflow.com/questions/46019771/catching-errors-in-angular-httpclient/53379715#53379715
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApplicationInsightsService } from '../services/logging/application-insights.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private loggingService: ApplicationInsightsService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          this.loggingService.logException(error.error);
        } else {
          this.loggingService.logException(
            new Error(
              `Backend returned code ${error.status}, body was: ${error.error}`
            )
          );
        }

        return throwError(() => error);
      })
    );
  }
}
