import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { StandardModalComponent } from 'src/app/core/components/modals/standard-modal/standard-modal.component';
import Utils from 'src/app/core/utils/utils';
import { FederationGatewayClientRoles } from 'src/app/core/models/usermanagement/federation-gateway-client-roles';
import { UserManagementService } from 'src/app/core/services/usermanagement/user-management.service';
import { CamelCaseToWordsPipe } from 'src/app/core/form/pipes/camel-case-to-words';

@Component({
  selector: 'app-role-dropdown',
  templateUrl: './role-dropdown.component.html',
  styleUrls: ['./role-dropdown.component.scss'],
})
export class RoleDropdownComponent implements OnInit {
  @ViewChild(StandardModalComponent) modal: StandardModalComponent;
  @Output() selectionChanged = new EventEmitter<FederationGatewayClientRoles>();

  private roles: FederationGatewayClientRoles[];
  public rolesDropdownList: string;

  constructor(
    private userManagementService: UserManagementService,
    private camelCaseToWordsPipe: CamelCaseToWordsPipe
  ) {}

  ngOnInit(): void {
    this.getRoles();
  }

  roleSelected(event) {
    var value = event.detail.value;
    this.selectionChanged.emit(this.roles.find((e) => e.id == value));
  }

  getRoles() {
    this.userManagementService.getRoles().subscribe({
      next: (response) => {
        this.roles = response;
        this.rolesDropdownList = this.ObjectListToDropdownList(response);
      },
      error: (e) => {
        this.modal.showServerError();
      },
    });
  }

  private ObjectListToDropdownList(response: any[]) {
    return Utils.ObjectListToDropdownList(
      response.map((role) => {
        return {
          name: this.camelCaseToWordsPipe.transform(role['name']),
          id: role['id'],
        };
      }),
      ['name'],
      'id'
    );
  }
}
