<div
  class="sdds-modal-backdrop"
  [ngClass]="modalShowing ? 'show' : 'hide'"
  *ngIf="modalShowing"
  @showingStateChange
>
  <div
    class="sdds-modal"
    [ngClass]="modalConfig.size"
    (clickOutside)="closeModal()"
  >
    <div class="sdds-modal-header">
      <div class="sdds-modal-headline">
        <h5>{{ modalConfig.title }}</h5>
      </div>
      <span class="sdds-modal-btn" (click)="closeModal()"></span>
    </div>
    <div class="sdds-modal-body">
      <p [innerHtml]="modalConfig.message"></p>
    </div>
    <div class="sdds-modal-actions sdds-u-mb2">
      <div class="sdds-modal-actions">
        <button
          *ngIf="modalType == modalTypeEnum.Confirmation"
          class="sdds-btn"
          [ngClass]="[
            modalConfig.confirmationButtonConfig.buttonType,
            modalConfig.confirmationButtonConfig.buttonSize
          ]"
          (click)="confirmationEvent.emit()"
        >
          {{ modalConfig.confirmationButtonConfig.buttonText }}
        </button>
        <button
          class="sdds-btn"
          [ngClass]="[
            modalConfig.closeButtonConfig.buttonType,
            modalConfig.closeButtonConfig.buttonSize
          ]"
          (click)="closeModal()"
        >
          {{ modalConfig.closeButtonConfig.buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
