import { Component, OnInit } from '@angular/core';
import { MessageConstants } from '../../services/message/message-constants';
import { MessageService } from '../../services/message/message.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  avatarMenuOpen: boolean = false;
  isAuthenticated: boolean = false;
  userProfile: string | null = null;

  constructor(
    private readonly messageService: MessageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();

    if (this.isAuthenticated) {
      this.userProfile = this.userService.populateUserInformation();
    }
  }

  openSideMenu() {
    this.messageService.sendMessage(MessageConstants.openSideMenu);
  }

  logout() {
    this.userService.logout();
  }
}
