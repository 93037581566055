import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-link',
  templateUrl: './menu-link.component.html',
})
export class MenuLinkComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() routerLink: string | undefined;
  @Input() currentRoute: string | undefined;

  constructor() {}

  ngOnInit(): void {}
}
