<form [formGroup]="datesForm">
  <div class="sdds-row">
    <div class="sdds-col-sm-6 sdds-col-xs-12">
      <app-date-picker
        ngDefaultControl
        label="Date From"
        formControlName="date-from"
        [max]="getDateRestriction('date-from', 'max')"
        [value]="getValue('date-from')"
      ></app-date-picker>
    </div>

    <div class="sdds-col-sm-6 sdds-col-xs-12">
      <app-date-picker
        ngDefaultControl
        label="Date To"
        formControlName="date-to"
        [min]="getDateRestriction('date-to', 'min')"
        [max]="getDateRestriction('date-to', 'max')"
        [value]="getValue('date-to')"
      >
      </app-date-picker>
    </div>
  </div>
</form>
