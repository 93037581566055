<div class="sdds-sidebar side-menu" [class.mobile-menu-open]="menuOpen">
  <div class="sdds-sidebar-mheader">
    <a class="sdds-sidebar-mheader__close" (click)="menuOpen = false">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.40338 2.34308C3.11048 2.05019 2.63561 2.05019 2.34272 2.34308C2.04982 2.63598 2.04982 3.11085 2.34272 3.40374L6.93897 8L2.34283 12.5961C2.04994 12.889 2.04994 13.3639 2.34283 13.6568C2.63572 13.9497 3.1106 13.9497 3.40349 13.6568L7.99963 9.06066L12.5958 13.6568C12.8887 13.9497 13.3635 13.9497 13.6564 13.6568C13.9493 13.3639 13.9493 12.889 13.6564 12.5961L9.06029 8L13.6565 3.40376C13.9494 3.11086 13.9494 2.63599 13.6565 2.3431C13.3636 2.0502 12.8888 2.0502 12.5959 2.3431L7.99963 6.93934L3.40338 2.34308Z"
          fill="#171719"
        />
      </svg>
    </a>
  </div>
  <ul class="sdds-sidebar-nav sdds-sidebar-nav--main">
    <ng-container>
      <app-sub-menu-link
        title="Pay Entry"
        [currentRoute]="currentRoute"
        [routerLinkPrefix]="'/payentry'"
      >
        <app-sub-menu-sub-link
          title="By Day"
          routerLink="/payentry"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
        <app-sub-menu-sub-link
          title="By Period"
          routerLink="/payentry/period"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
      </app-sub-menu-link>

      <app-menu-link
        *ngIf="
          userIsSuperuser() ||
          userIsGeneralManager() ||
          userIsAssistantGeneralManager()
        "
        title="Sickness Reasons"
        routerLink="/sicknessreasons"
        [currentRoute]="currentRoute"
      ></app-menu-link>

      <app-sub-menu-link
        title="View Reports"
        [currentRoute]="currentRoute"
        [routerLinkPrefix]="'/viewreports'"
      >
        <app-sub-menu-sub-link
          title="Pay Entry Report"
          routerLink="/viewreports/viewreports"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="Employee Entries"
          routerLink="/viewreports/employeeentries"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
      </app-sub-menu-link>

      <app-sub-menu-link
        title="Manage Users"
        *ngIf="userIsSuperuser()"
        [currentRoute]="currentRoute"
        [routerLinkPrefix]="'/manageusers'"
      >
        <app-sub-menu-sub-link
          title="Manage User"
          routerLink="/manageusers"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
        <app-sub-menu-sub-link
          title="Add User"
          routerLink="/manageusers/adduser"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
      </app-sub-menu-link>

      <app-sub-menu-link
        title="System Tools"
        *ngIf="userIsSuperuser()"
        [currentRoute]="currentRoute"
        [routerLinkPrefix]="'/systemtools'"
      >
        <app-sub-menu-sub-link
          title="Configure Pay Elements"
          routerLink="/systemtools/configureelements"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
        <app-sub-menu-sub-link
          title="Mail Administrators"
          routerLink="/systemtools/mailadmins"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="Post News Items"
          routerLink="/systemtools/news"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="Remove Authorisation"
          routerLink="/systemtools/removeauth"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
      </app-sub-menu-link>

      <app-sub-menu-link
        title="Admin"
        *ngIf="userIsSuperuser()"
        [currentRoute]="currentRoute"
        [routerLinkPrefix]="'/admin'"
      >
        <app-sub-menu-sub-link
          title="Authorisation Report"
          routerLink="/admin/authorisationreport"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="Element Report"
          routerLink="/admin/elementreport"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="User Element Report"
          routerLink="/admin/userelementreport"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="Location Admin Report"
          routerLink="/admin/locationadminreport"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="Audit Report"
          routerLink="/admin/auditreport"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>

        <app-sub-menu-sub-link
          title="User Role Report"
          routerLink="/admin/userrolereport"
          [currentRoute]="currentRoute"
        ></app-sub-menu-sub-link>
      </app-sub-menu-link>

      <app-menu-link
        *ngIf="userIsSuperuser()"
        title="Variable Export"
        routerLink="/variableexport"
        [currentRoute]="currentRoute"
      ></app-menu-link>

      <app-menu-link
        *ngIf="userIsSuperuser()"
        title="TAFW Export"
        routerLink="/tafwexport"
        [currentRoute]="currentRoute"
      ></app-menu-link
    ></ng-container>
  </ul>
</div>
