import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-menu-link',
  templateUrl: './sub-menu-link.component.html',
})
export class SubMenuLinkComponent implements OnInit {
  @Input() title: string | undefined;
  open: boolean = false;
  @Input() currentRoute: string | '';
  @Input() routerLinkPrefix: string | '';

  constructor() {}

  ngOnInit(): void {
    if (this.currentRoute.startsWith(this.routerLinkPrefix)) {
      this.open = true;
    }
  }
}
