<div class="sdds-textfield-slot-wrap-label" *ngIf="label">
  <span slot="sdds-label">{{ label }}</span>
</div>
<div class="sdds-form-datetime">
  <input
    class="sdds-datetime-input"
    type="date"
    [value]="value | date : 'yyyy-MM-dd'"
    [max]="max | date : 'yyyy-MM-dd'"
    [min]="min | date : 'yyyy-MM-dd'"
  />
</div>
