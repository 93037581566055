<sdds-dropdown-filter
  placeholder="Select an Option"
  label="Role"
  label-position="outside"
  [data]="rolesDropdownList"
  (selectOption)="roleSelected($event)"
  open-direction="down"
>
</sdds-dropdown-filter>

<app-standard-modal></app-standard-modal>
