import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EditUserDTO } from '../../models/usermanagement/edit-user-dto';
import { FederationGatewayClientRoles } from '../../models/usermanagement/federation-gateway-client-roles';
import { NewUserDTO } from '../../models/usermanagement/new-user-dto';
import { KPayLocation } from '../../models/kpaylocation';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  apiUrl = `${environment.api.url}api/UserManagement`;

  constructor(private http: HttpClient) {}

  getRoles(): Observable<FederationGatewayClientRoles[]> {
    return this.http
      .get<FederationGatewayClientRoles[]>(this.apiUrl + `/Roles`)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getLocations(email: string): Observable<KPayLocation[]> {
    return this.http
      .get<KPayLocation[]>(this.apiUrl + `/Locations/` + email)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getUsersInRoles(): Observable<EditUserDTO[]> {
    return this.http
      .get<EditUserDTO[]>(this.apiUrl + `/UsersInRoles`)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  addUser(userRoles: NewUserDTO): Observable<any> {
    return this.http.post(this.apiUrl + `/AddUser`, userRoles);
  }

  editUser(editUser: EditUserDTO): Observable<any> {
    this.cancelPrevious();
    return this.http
      .post<EditUserDTO>(this.apiUrl + `/EditUser`, editUser)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  deleteUser(userId: string): Observable<any> {
    this.cancelPrevious();
    return this.http
      .delete<string[]>(this.apiUrl + `/RemoveUser/` + userId)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  // Cancel previous subscription and create a new one.
  cancelPrevious() {
    if (!this.ngUnsubscribe.observers) {
      this.ngUnsubscribe = new Subject<void>();
    }

    this.ngUnsubscribe.next();
  }

  // Destroy subscription
  unsubscribe() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
