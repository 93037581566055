import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { SideMenuComponent } from './core/components/side-menu/side-menu.component';
import { LayoutComponent } from './core/components/layout/layout.component';
import { initializeKeycloak } from './core/keycloak/keycloak';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './features/login/login.module';
import { MenuLinkComponent } from './core/components/side-menu/components/menu-link/menu-link.component';
import { SubMenuLinkComponent } from './core/components/side-menu/components/sub-menu-link/sub-menu-link.component';
import { SubMenuSubLinkComponent } from './core/components/side-menu/components/sub-menu-sub-link/sub-menu-sub-link.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './core/interceptors/global-error-handler';
import { HttpErrorInterceptor } from './core/interceptors/http-error-Interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SideMenuComponent,
    LayoutComponent,
    MenuLinkComponent,
    SubMenuLinkComponent,
    SubMenuSubLinkComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    SharedModule,
    LoginModule,
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
