import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { StandardModalComponent } from 'src/app/core/components/modals/standard-modal/standard-modal.component';
import { KPayLocation } from 'src/app/core/models/kpaylocation';
import { LocationService } from 'src/app/core/services/location/location.service';
import Utils from 'src/app/core/utils/utils';

@Component({
  selector: 'app-location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss'],
})
export class LocationDropdownComponent implements OnInit {
  @ViewChild(StandardModalComponent) modal: StandardModalComponent;
  @Output() selectionChanged = new EventEmitter<KPayLocation>();

  private locations: KPayLocation[];
  public locationsDropdownList: string;

  constructor(public locationService: LocationService) {}

  ngOnInit(): void {
    this.getLocations();
  }

  locationSelected(event) {
    var value = event.detail.value;
    this.selectionChanged.emit(this.locations.find((e) => e.fullName == value));
  }

  getLocations() {
    this.locationService.getLocations().subscribe({
      next: (response) => {
        this.locations = response;
        this.locationsDropdownList = Utils.ObjectListToDropdownList(
          response,
          ['dealer', 'depot', 'department'],
          'fullName'
        );
      },
      error: (e) => {
        if (e.error?.Message) this.modal.showServerError(e.error.Message);
        else this.modal.showServerError();
      },
    });
  }
}
