import { Pipe, PipeTransform } from '@angular/core';
import { checkable } from 'src/app/core/models/checkable';

@Pipe({
  name: 'hasCheckedItem',
  pure: false,
})
export class HasCheckedItemPipe implements PipeTransform {
  transform(roles: checkable[]): Boolean {
    if (!roles) return false;
    return roles.some((item) => {
      return item.checked === true;
    });
  }
}
