import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
  DateRangePickerModel,
  DateRangePickerRestrictions,
} from './date-range-picker.model';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
  @Output() datesChanged = new EventEmitter<DateRangePickerModel>();
  @Input() disableTodayDateRestriction: boolean;
  @Input() defaultStartDate: Date;
  @Input() defaultEndDate: Date;

  public dateRestrictions: DateRangePickerRestrictions;
  public datesForm = this.formBuilder.group({
    'date-from': [undefined],
    'date-to': [undefined],
  });

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.datesForm.valueChanges.subscribe((value: any) => {
      const dateFrom = new Date(value['date-from']);
      const dateTo = new Date(value['date-to']);
      const today = new Date();

      if (this.disableTodayDateRestriction) {
        this.dateRestrictions = {
          'date-from': {
            max: dateTo,
          },
          'date-to': {
            min: dateFrom,
          },
        };
      } else {
        const max = dateTo && dateTo < today ? dateTo : today;
        this.dateRestrictions = {
          'date-from': {
            max: max,
          },
          'date-to': {
            ...(dateFrom && { min: dateFrom }),
            max: today,
          },
        };
      }

      var changeValue: DateRangePickerModel = {
        'date-from': dateFrom,
        'date-to': dateTo,
      };
      this.datesChanged.emit(changeValue);
    });

    if (this.defaultStartDate) {
      this.datesForm.controls['date-from'].setValue(this.defaultStartDate);
    }

    if (this.defaultEndDate) {
      this.datesForm.controls['date-to'].setValue(this.defaultEndDate);
    }
  }

  getValue(controlName: string) {
    return this.datesForm.controls[controlName].value;
  }

  getDateRestriction(controlName: string, restrictionEnd: string) {
    return this.dateRestrictions
      ? this.dateRestrictions[controlName][restrictionEnd]
      : undefined;
  }
}
