import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationError } from 'src/app/core/form/validation-error';

@Pipe({
  name: 'getError',
  pure: false,
})
export class GetErrorPipe implements PipeTransform {
  constructor() {}

  transform(control: AbstractControl): string {
    if (
      !control.errors ||
      !control.invalid ||
      (!control.dirty && !control.touched)
    ) {
      return '';
    }

    if (control.errors['required']) {
      return `This field is required.`;
    }

    const validationError = control.errors as ValidationError;
    if (validationError && validationError.errorMessage) {
      return validationError.errorMessage;
    }

    return 'unknown error';
  }
}
