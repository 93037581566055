import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  forwardRef,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import {
  IItemsMovedEvent,
  IDualListBoxItem,
} from 'src/app/shared/components/dual-list-box/dual-list.models';

@Component({
  selector: 'dual-list-box',
  templateUrl: 'dual-list-box.component.html',
  styleUrls: ['dual-list-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DualListBoxComponent),
      multi: true,
    },
  ],
})
export class DualListBoxComponent implements OnInit, ControlValueAccessor {
  // array of items to display in the lists, determined by the selected property
  @Input() set data(list: Array<IDualListBoxItem>) {
    this.availableItems = list.filter((i) => !i.selected);
    this.selectedItems = list.filter((i) => i.selected);

    this.onItemsMoved.emit({
      available: this.availableItems,
      selected: this.selectedItems,
      movedItems: this.availableListBoxControl.value,
      from: 'available',
      to: 'selected',
    });

    this.writeValue(this.getValues());
  }
  // input to set search term for available list box from the outside
  @Input() set availableSearch(searchTerm: string) {
    this.searchTermAvailable = searchTerm;
    this.availableSearchInputControl.setValue(searchTerm);
  }
  // input to set search term for selected list box from the outside
  @Input() set selectedSearch(searchTerm: string) {
    this.searchTermSelected = searchTerm;
    this.selectedSearchInputControl.setValue(searchTerm);
  }
  // text to display as title above component
  @Input() title: string;
  // time to debounce search output in ms
  @Input() debounceTime = 500;
  // show/hide button to move all items between boxes
  @Input() moveAllButton = true;
  // text displayed over the available items list box
  @Input() availableText = 'Available items';
  // text displayed over the selected items list box
  @Input() selectedText = 'Selected items';
  // text displayed in tooltip
  @Input() itemText = 'Item';
  // set placeholder text in available items list box
  @Input() availableFilterPlaceholder = 'Filter...';
  // set placeholder text in selected items list box
  @Input() selectedFilterPlaceholder = 'Filter...';

  // event called when item or items from available items(left box) is selected
  @Output() onAvailableItemSelected: EventEmitter<{} | Array<{}>> =
    new EventEmitter<{} | Array<{}>>();
  // event called when item or items from selected items(right box) is selected
  @Output() onSelectedItemsSelected: EventEmitter<{} | Array<{}>> =
    new EventEmitter<{} | Array<{}>>();
  // event called when items are moved between boxes, returns state of both boxes and item moved
  @Output() onItemsMoved: EventEmitter<IItemsMovedEvent> =
    new EventEmitter<IItemsMovedEvent>();

  // private variables to manage class
  searchTermAvailable = '';
  searchTermSelected = '';
  availableItems: Array<IDualListBoxItem> = [];
  selectedItems: Array<IDualListBoxItem> = [];
  listBoxForm: FormGroup;
  availableListBoxControl: FormControl = new FormControl();
  selectedListBoxControl: FormControl = new FormControl();
  availableSearchInputControl: FormControl = new FormControl();
  selectedSearchInputControl: FormControl = new FormControl();

  // control value accessors
  _onChange = (_: any) => {};
  _onTouched = () => {};

  constructor(public fb: FormBuilder) {
    this.listBoxForm = this.fb.group({
      availableListBox: this.availableListBoxControl,
      selectedListBox: this.selectedListBoxControl,
      availableSearchInput: this.availableSearchInputControl,
      selectedSearchInput: this.selectedSearchInputControl,
    });
  }

  ngOnInit(): void {
    this.availableListBoxControl.valueChanges.subscribe((items: Array<{}>) =>
      this.onAvailableItemSelected.emit(items)
    );
    this.selectedListBoxControl.valueChanges.subscribe((items: Array<{}>) =>
      this.onSelectedItemsSelected.emit(items)
    );
    this.availableSearchInputControl.valueChanges
      .pipe(debounceTime(this.debounceTime))
      .pipe(distinctUntilChanged())
      .subscribe((search: string) => (this.searchTermAvailable = search));
    this.selectedSearchInputControl.valueChanges
      .pipe(debounceTime(this.debounceTime))
      .pipe(distinctUntilChanged())
      .subscribe((search: string) => (this.searchTermSelected = search));
  }

  /* Move all items from available to selected */
  moveAllItemsToSelected(): void {
    if (!this.availableItems.length) {
      return;
    }
    this.selectedItems = [...this.selectedItems, ...this.availableItems];
    this.availableItems = [];
    this.availableListBoxControl.setValue([]);
    this.raiseItemsMovedFrom('available');

    this.availableSearchInputControl.setValue('');
    this.selectedSearchInputControl.setValue('');
  }

  /* Move all items from selected to available */
  moveAllItemsToAvailable(): void {
    if (!this.selectedItems.length) {
      return;
    }
    this.availableItems = [...this.availableItems, ...this.selectedItems];
    this.selectedItems = [];
    this.selectedListBoxControl.setValue([]);
    this.raiseItemsMovedFrom('selected');

    this.availableSearchInputControl.setValue('');
    this.selectedSearchInputControl.setValue('');
  }

  /* Move marked items from available items to selected items */
  moveMarkedAvailableItemsToSelected(): void {
    // first move items to selected
    this.selectedItems = [
      ...this.selectedItems,
      ...this.intersectionwith(
        this.availableItems,
        this.availableListBoxControl.value
      ),
    ];
    // now filter available items to not include marked values
    this.availableItems = [
      ...this.differenceWith(
        this.availableItems,
        this.availableListBoxControl.value
      ),
    ];
    // clear marked available items and emit event
    this.availableListBoxControl.setValue([]);
    //this.availableSearchInputControl.setValue('');
    this.raiseItemsMovedFrom('available');
  }

  /* Move marked items from selected items to available items */
  moveMarkedSelectedItemsToAvailable(): void {
    // first move items to available
    this.availableItems = [
      ...this.availableItems,
      ...this.intersectionwith(
        this.selectedItems,
        this.selectedListBoxControl.value
      ),
    ];
    // now filter available items to not include marked values
    this.selectedItems = [
      ...this.differenceWith(
        this.selectedItems,
        this.selectedListBoxControl.value
      ),
    ];
    // clear marked available items and emit event
    this.selectedListBoxControl.setValue([]);
    //this.selectedSearchInputControl.setValue('');
    this.raiseItemsMovedFrom('selected');
  }

  /* Move single item from available to selected */
  moveAvailableItemToSelected(item: IDualListBoxItem): void {
    this.availableItems = this.availableItems.filter(
      (listItem: IDualListBoxItem) => listItem.value !== item.value
    );
    this.selectedItems = [...this.selectedItems, item];
    //this.availableSearchInputControl.setValue('');
    this.availableListBoxControl.setValue([]);
    this.raiseItemsMovedFrom('available');
  }

  /* Move single item from selected to available */
  moveSelectedItemToAvailable(item: IDualListBoxItem): void {
    this.selectedItems = this.selectedItems.filter(
      (listItem: IDualListBoxItem) => listItem.value !== item.value
    );
    this.availableItems = [...this.availableItems, item];
    //this.selectedSearchInputControl.setValue('');
    this.selectedListBoxControl.setValue([]);
    this.raiseItemsMovedFrom('selected');
  }

  /* emit onItemsMoved and writeValue */
  raiseItemsMovedFrom(source: 'selected' | 'available') {
    this.onItemsMoved.emit({
      available: this.availableItems,
      selected: this.selectedItems,
      movedItems:
        source == 'selected'
          ? this.selectedListBoxControl.value
          : this.availableListBoxControl.value,
      from: source,
      to: source == 'selected' ? 'available' : 'selected',
    });

    this.writeValue(this.getValues());
  }

  /*  Function to pass to ngFor to improve performance, tracks items by the value field */
  trackByValue(index: number, item: IDualListBoxItem): string {
    return item.value;
  }

  /* Methods from ControlValueAccessor interface, required for ngModel and formControlName - begin */
  writeValue(value: string[]): void {
    if (this.selectedItems && value && value.length > 0) {
      this.selectedItems = [
        ...this.selectedItems,
        ...this.intersectionwith(this.availableItems, value),
      ];
      this.availableItems = [
        ...this.differenceWith(this.availableItems, value),
      ];
    }
    this._onChange(value);
  }

  registerOnChange(fn: (_: any) => {}): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this._onTouched = fn;
  }
  /* Methods from ControlValueAccessor interface, required for ngModel and formControlName - end */

  /* Utility methods to get values from selected items */
  private getValues(): string[] {
    return (
      (this.selectedItems || []).map((item: IDualListBoxItem) => item.value) ??
      []
    );
  }

  differenceWith(
    arr1: Array<IDualListBoxItem>,
    arr2: string[]
  ): Array<IDualListBoxItem> {
    return arr1.filter((a) => arr2.findIndex((b) => a.value == b) === -1);
  }

  intersectionwith(
    arr1: Array<IDualListBoxItem>,
    arr2: string[]
  ): Array<IDualListBoxItem> {
    return arr1.filter((val1) => {
      return arr2.find((val2) => val1.value === val2);
    });
  }
}
