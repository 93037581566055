<li
  class="sdds-sidebar-nav__item sdds-sidebar-nav__extended"
  [class.subnav-open]="open"
>
  <a class="sdds-sidebar-nav__item-link" (click)="open = !open">
    <span class="sdds-sidebar-nav__item-text">{{ title }}</span>
    <svg
      class="sdds-sidebar-nav__chevron"
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L6 6L11 1"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  </a>
  <ul class="sdds-sidebar-nav-subnav">
    <ng-content></ng-content>
  </ul>
</li>
