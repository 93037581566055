import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsService } from '../services/logging/application-insights.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private loggingService: ApplicationInsightsService) {
    super();
  }

  override handleError(error: Error) {
    this.loggingService.logException(error);
  }
}
