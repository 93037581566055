import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { from } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    return await keycloak.init({
      config: {
        url: environment.keycloak.url,
        clientId: environment.keycloak.clientId,
        realm: environment.keycloak.realm,
      },
      initOptions: {
        onLoad: 'check-sso',
      },
      enableBearerInterceptor: true,
    });
  };
}

export function initializeKeycloakTokenRefresh(keycloak: KeycloakService) {
  from(keycloak.keycloakEvents$)
    .pipe(filter((event) => event.type === KeycloakEventType.OnTokenExpired))
    .subscribe(() => {
      keycloak.updateToken();
    });
}
