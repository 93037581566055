import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SDDSButtonType } from 'src/app/core/sdds/class-definitions/buttons/sdds-button-type';
import { ModalSize } from 'src/app/core/sdds/class-definitions/modals/modal-size';
import { ButtonConfig } from '../models/button-config';
import { ModalConfig } from '../models/modal-config';
import { ModalType } from './modal-type';

@Component({
  selector: 'app-standard-modal',
  templateUrl: './standard-modal.component.html',
  styleUrls: ['./standard-modal.component.css'],
  animations: [
    [
      trigger('showingStateChange', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('200ms ease-out', style({ opacity: 1 })),
        ]),
        transition(':leave', [
          style({ opacity: 1 }),
          animate('200ms ease-in', style({ opacity: 0 })),
        ]),
      ]),
    ],
  ],
})
export class StandardModalComponent {
  modalTypeEnum: typeof ModalType = ModalType;
  modalShowing: boolean = false;
  modalConfig: ModalConfig;

  @Input() modalType: ModalType = ModalType.Standard;
  @Output() confirmationEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();

  showModal(modalConfig: ModalConfig) {
    this.modalConfig = modalConfig;
    this.modalShowing = true;
  }

  closeModal() {
    this.modalShowing = false;
    if (this.modalConfig.trackCloseEvent) this.closeEvent.emit();
  }

  showServerError(errorMessage: string = null) {
    this.modalType = ModalType.Standard;

    this.showModal(
      new ModalConfig({
        title: 'Server Error',
        size: ModalSize.small,
        message:
          errorMessage ??
          'Oops, something went wrong. Help us improve your experience by sending an error report to IT support.',
      })
    );
  }

  showModalDelete(
    title: string,
    message: string,
    size: ModalSize = ModalSize.small,
    deleteButtonText: string = 'Delete'
  ) {
    var closeButtonConfig = new ButtonConfig();
    closeButtonConfig.buttonText = 'Cancel';

    var confirmationButtonConfig = new ButtonConfig();
    confirmationButtonConfig.buttonText = deleteButtonText;
    confirmationButtonConfig.buttonType = SDDSButtonType.dangerButton;

    this.modalType = ModalType.Confirmation;

    this.showModal(
      new ModalConfig({
        title: title,
        size: size,
        closeButtonConfig: closeButtonConfig,
        confirmationButtonConfig: confirmationButtonConfig,
        message: message,
      })
    );
  }

  showModalConfirmation(
    title: string,
    message: string,
    size: ModalSize = ModalSize.small,
    confirmButtonText: string = 'Confirm',
    closeButtonText: string = 'Cancel'
  ) {
    var closeButtonConfig = new ButtonConfig();
    closeButtonConfig.buttonText = closeButtonText;

    var confirmationButtonConfig = new ButtonConfig();
    confirmationButtonConfig.buttonText = confirmButtonText;
    confirmationButtonConfig.buttonType = SDDSButtonType.primaryButton;

    this.modalType = ModalType.Confirmation;

    this.showModal(
      new ModalConfig({
        title: title,
        size: size,
        closeButtonConfig: closeButtonConfig,
        confirmationButtonConfig: confirmationButtonConfig,
        message: message,
      })
    );
  }
}
