<sdds-dropdown-filter
  placeholder="Select an Option"
  label="Element"
  label-position="outside"
  [data]="elementsDropdownList"
  (selectOption)="elementSelected($event)"
  open-direction="down"
>
</sdds-dropdown-filter>

<app-standard-modal></app-standard-modal>
