import { Component, Inject } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { initializeKeycloakTokenRefresh } from './core/keycloak/keycloak';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ApplicationInsightsService } from './core/services/logging/application-insights.service';
import { UserService } from './core/services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected keycloak: KeycloakService,
    protected applicationInsightsService: ApplicationInsightsService,
    protected userService: UserService
  ) {
    let userId = userService.getUserId();
    if (userId) {
      this.applicationInsightsService?.appInsights?.setAuthenticatedUserContext(
        userId
      );
    }
  }

  ngOnInit(): void {
    this.document.body.setAttribute('data-env', environment.dataENV);
    initializeKeycloakTokenRefresh(this.keycloak);
  }
}
