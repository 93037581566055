import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastType } from 'src/app/core/components/toast/sdds-toast-type';
import { News } from 'src/app/core/models/news';
import { NewsService } from 'src/app/core/services/news/news.service';

@Component({
  selector: 'app-news-component',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  constructor(
    private newsService: NewsService,
    private toastr: ToastrService
  ) {}

  news: News = { subject: '', body: '' };

  ngOnInit(): void {
    this.newsService.get().subscribe({
      next: (result: News[]) =>
        (this.news = result.length ? result[0] : { subject: '', body: '' }),
      error: (e: HttpErrorResponse) =>
        this.toastr.show('The news has failed to load.', 'News', {
          toastClass: ToastType.ToastError,
        }),
    });
  }
}
