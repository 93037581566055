import { Pipe, PipeTransform } from '@angular/core';
import { IDualListBoxItem } from 'src/app/shared/components/dual-list-box/dual-list.models';

/**
 * Utility class to not hardcode sort directions
 */
export class SortOptions {
  /**
   * Static property to defined ASC and DESC values
   * to avoid hardcoding and repeating
   * replaces string enums
   */
  static direction: {
    ASC: string;
    DESC: string;
  } = {
    ASC: 'ASC',
    DESC: 'DESC',
  };
}

/**
 * Pipe used to sort arrays
 * Takes array and array of 2 strings(parameters), key and direction
 * direction must be either ASC or DESC
 */
@Pipe({
  name: 'arraySort',
})
export class DualListSortPipe implements PipeTransform {
  transform(
    array: Array<IDualListBoxItem>,
    args: string[]
  ): Array<IDualListBoxItem> {
    array = array || [];

    if (typeof args === 'undefined' || args.length !== 2) {
      return array;
    }

    const [key, direction] = args;
    var sortOrder = direction == SortOptions.direction.DESC ? -1 : 1;

    array.sort((itemA: IDualListBoxItem, itemB: IDualListBoxItem) => {
      var valueA = itemA.hasOwnProperty(key) ? itemA[key] : itemA;
      var valueB = itemB.hasOwnProperty(key) ? itemB[key] : itemB;
      return valueA < valueB ? -sortOrder : valueA > valueB ? sortOrder : 0;
    });
    return array;
  }
}

/**
 * Pipe used to filter array, takes input array and
 * array of 2 arguments, key of object and search term
 * if key does not exist, pipe assumes the item is string
 */
@Pipe({
  name: 'arrayFilter',
})
export class DualListFilterPipe implements PipeTransform {
  transform(
    array: Array<IDualListBoxItem>,
    args: string[]
  ): Array<IDualListBoxItem> {
    array = array || [];

    if (typeof args === 'undefined' || args.length !== 2) {
      return array;
    }

    const [key, searchTerm] = args;

    if (searchTerm.trim() === '') {
      return array;
    }

    return array.filter(
      (item: {}) =>
        item[key]
          .toString()
          .toLowerCase()
          .search(searchTerm.toLowerCase().trim()) >= 0
    );
  }
}
