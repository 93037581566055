import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  LocationElement,
  LocationElementDTO,
} from '../../models/elementlocation';
import { KPayElement } from '../../models/kpayelement';

@Injectable({
  providedIn: 'root',
})
export class ElementService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  apiUrl = `${environment.api.url}api/Element`;

  constructor(private http: HttpClient) {}

  getElements(): Observable<KPayElement[]> {
    this.cancelPrevious();

    return this.http
      .get<KPayElement[]>(`${this.apiUrl}`)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getTimeAwayFromWorkElements(): Observable<KPayElement[]> {
    this.cancelPrevious();

    return this.http
      .get<KPayElement[]>(`${this.apiUrl}/TimeAwayFromWorkElements`)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getLocationElements(locationFullName: string): Observable<LocationElement[]> {
    this.cancelPrevious();

    return this.http
      .get<LocationElement[]>(
        `${this.apiUrl}/LocationElements/${locationFullName}`
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  GetLocationElementsForDateRange(
    locationFullName: string,
    fromDate: Date,
    toDate: Date
  ): Observable<LocationElement[]> {
    this.cancelPrevious();

    return this.http
      .get<LocationElement[]>(
        `${
          this.apiUrl
        }/LocationElements/${locationFullName}/${fromDate.toISOString()}/${toDate.toISOString()}`
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  getLocationElementsFromDate(
    locationFullName: string,
    date: Date
  ): Observable<LocationElement[]> {
    this.cancelPrevious();

    return this.http
      .get<LocationElement[]>(
        `${
          this.apiUrl
        }/LocationElements/${locationFullName}/${date.toISOString()}`
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  updateLocationElements(
    locationFullName: string,
    locationElements: LocationElementDTO[]
  ): Observable<LocationElement[]> {
    this.cancelPrevious();
    return this.http
      .post<LocationElement[]>(
        `${this.apiUrl}/UpdateLocationElements/${locationFullName}`,
        locationElements
      )
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  // Cancel previous subscription and create a new one.
  cancelPrevious() {
    if (!this.ngUnsubscribe.observers) {
      this.ngUnsubscribe = new Subject<void>();
    }

    this.ngUnsubscribe.next();
  }

  // Destroy subscription
  unsubscribe() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
