import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KPayLocation } from '../../models/kpaylocation';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  apiUrl = `${environment.api.url}api/Location`;

  constructor(private http: HttpClient) {}

  getLocations(): Observable<KPayLocation[]> {
    this.cancelPrevious();

    return this.http
      .get<KPayLocation[]>(this.apiUrl)
      .pipe(takeUntil(this.ngUnsubscribe));
  }

  // Cancel previous subscription and create a new one.
  cancelPrevious() {
    if (!this.ngUnsubscribe.observers) {
      this.ngUnsubscribe = new Subject<void>();
    }

    this.ngUnsubscribe.next();
  }

  // Destroy subscription
  unsubscribe() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
