import { environmentBase } from './enviroment.base';

const env: Partial<typeof environmentBase> = {
  instrumentationKey: '65c972d3-1234-444d-8bea-5902aa7ad909',
  dataENV: 'DEV',
  keycloak: {
    realm: 'scania',
    url: 'https://fg.ciam.preprod.aws.scania.com/auth',
    clientId: 'kpay_dev',
  },
  api: {
    url: 'https://dev.timesheets.uk.devtest.aws.scania.com/',
  },
};

export const environment = Object.assign(environmentBase, env);
