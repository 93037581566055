import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'showError',
  pure: false,
})
export class ShowErrorPipe implements PipeTransform {
  constructor() {}

  transform(control: AbstractControl): string {
    return control.invalid && (control.dirty || control.touched) ? 'error' : '';
  }
}
