import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { StandardModalComponent } from 'src/app/core/components/modals/standard-modal/standard-modal.component';
import { SDDSToast } from 'src/app/core/components/toast/sdds-toast.component';
import { CamelCaseToWordsPipe } from 'src/app/core/form/pipes/camel-case-to-words';
import { GetErrorPipe } from 'src/app/core/form/pipes/get-error';
import { HasCheckedItemPipe } from 'src/app/core/form/pipes/has-checked-item.pipe';
import { ShowErrorPipe } from 'src/app/core/form/pipes/show-error.pipe';
import { LoadingSpinnerInterceptor } from 'src/app/core/interceptors/loading-spinner.interceptor';
import { ClientRolesCheckboxComponent } from 'src/app/shared/components/client-roles-checkbox/client-roles-checkbox.component';
import { DualListBoxComponent } from 'src/app/shared/components/dual-list-box/dual-list-box.component';
import { NewsComponent } from 'src/app/shared/components/news/news.component';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { ElementDropdownComponent } from './components/element-dropdown/element-dropdown.component';
import { EmployeeDropdownComponent } from './components/employee-dropdown/employee-dropdown.component';
import { LocationDropdownComponent } from './components/location-dropdown/location-dropdown.component';
import { RoleDropdownComponent } from './components/role-dropdown/role-dropdown.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MonthPickerComponent } from './components/month-picker/month-picker.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PayEntryInputComponent } from './components/pay-entry-input/pay-entry-input.component';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';

import { Angular2SmartTableModule } from 'angular2-smart-table';
import {
  DualListFilterPipe,
  DualListSortPipe,
} from 'src/app/shared/components/dual-list-box/dual-list.pipes';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      toastComponent: SDDSToast,
      preventDuplicates: true,
    }),
    Angular2SmartTableModule,
  ],
  declarations: [
    PageHeaderComponent,
    SDDSToast,
    NewsComponent,
    StandardModalComponent,
    SpinnerComponent,
    LocationDropdownComponent,
    EmployeeDropdownComponent,
    ElementDropdownComponent,
    RoleDropdownComponent,
    PayEntryInputComponent,
    MonthPickerComponent,
    MessagesComponent,
    TwoDigitDecimalNumberDirective,
    GetErrorPipe,
    ShowErrorPipe,
    CamelCaseToWordsPipe,
    HasCheckedItemPipe,
    ClientRolesCheckboxComponent,
    DualListBoxComponent,
    DualListSortPipe,
    DualListFilterPipe,
    DatePickerComponent,
    DateRangePickerComponent,
  ],
  exports: [
    PageHeaderComponent,
    NewsComponent,
    StandardModalComponent,
    SpinnerComponent,
    LocationDropdownComponent,
    EmployeeDropdownComponent,
    ElementDropdownComponent,
    RoleDropdownComponent,
    PayEntryInputComponent,
    MonthPickerComponent,
    MessagesComponent,
    TwoDigitDecimalNumberDirective,
    GetErrorPipe,
    ShowErrorPipe,
    HasCheckedItemPipe,
    CamelCaseToWordsPipe,
    ClientRolesCheckboxComponent,
    DualListBoxComponent,
    DualListSortPipe,
    DualListFilterPipe,
    DatePickerComponent,
    DateRangePickerComponent,
    Angular2SmartTableModule,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CamelCaseToWordsPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingSpinnerInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
