import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidationError } from 'src/app/core/form/validation-error';
import { FederationGatewayClientRoles } from 'src/app/core/models/usermanagement/federation-gateway-client-roles';

@Component({
  selector: 'app-client-roles-checkbox',
  templateUrl: './client-roles-checkbox.component.html',
  styleUrls: ['./client-roles-checkbox.component.css'],
})
export class ClientRolesCheckboxComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() clientRoles: FederationGatewayClientRoles[] = null;
  @Input() disabled: boolean = false;

  @Output() formChange = new EventEmitter<FormGroup>();
  @Output() clientRolesChange = new EventEmitter<
    FederationGatewayClientRoles[]
  >();

  constructor() {}

  ngOnInit(): void {}

  validateRoles() {
    this.form.controls['Roles'].reset();

    if (!this.isRoleChecked(this.clientRoles)) {
      this.form.controls['Roles'].setErrors(
        new ValidationError('At least one Role must be selected', 'required')
      );
      this.form.controls['Roles'].markAsDirty();
    }

    this.formChange.emit(this.form);
    this.clientRolesChange.emit(this.clientRoles);
  }

  isRoleChecked(roles: FederationGatewayClientRoles[]): boolean {
    if (!roles) return false;
    return roles.some((item) => item.checked == true);
  }
}
