<nav class="sdds-nav" [class.sdds-nav__avatar--opened]="avatarMenuOpen">
  <div class="sdds-nav__left">
    <div class="sdds-nav__mob-menu-btn" (click)="openSideMenu()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.97 6.998a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 15.982a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1ZM3.97 24.966a1 1 0 0 1 1-1h22.05a1 1 0 0 1 0 2H4.97a1 1 0 0 1-1-1Z"
          fill="currentColor"
        />
      </svg>
    </div>
    <div class="sdds-nav__app-name">Scania Timesheets</div>
  </div>

  <div class="sdds-nav__center">
    <ul class="sdds-nav__inline-menu"></ul>
  </div>

  <div class="sdds-nav__right">
    <ul class="sdds-nav__toolbar-menu">
      <li class="sdds-nav__item sdds-nav__avatar" *ngIf="isAuthenticated">
        <button
          class="sdds-nav__avatar-btn"
          (click)="avatarMenuOpen = !avatarMenuOpen"
          (clickOutside)="avatarMenuOpen = false"
          (clickOutsideEnabled)="(avatarMenuOpen)"
        >
          <img
            class="sdds-nav__avatar-img"
            src="https://www.svgrepo.com/show/170303/avatar.svg"
            alt="profile photo"
          />
        </button>

        <ul class="sdds-nav__avatar-menu">
          <li class="sdds-nav__avatar-item sdds-nav__avatar-item--large">
            <div class="sdds-nav__avatar-info">
              <p class="sdds-nav__avatar-title">{{ userProfile }}</p>
              <p class="sdds-nav__avatar-subtitle">Scania</p>
            </div>
          </li>
          <li class="sdds-nav__avatar-item">
            <a class="sdds-nav__avatar-item-core" (click)="logout()">Logout</a>
          </li>
        </ul>
      </li>
      <a class="sdds-nav__item sdds-nav__app-logo"></a>
    </ul>
  </div>
</nav>
