<div class="sdds-row">
  <div class="sdds-col-xs-12">
    <h3 class="sdds-headline-02">{{ title }}</h3>
    <p class="sdds-headline-06 sdds-text-grey-600 sdds-mt-0" *ngIf="subtitle">
      {{ subtitle }}
    </p>
  </div>
</div>
<div class="sdds-row">
  <div class="sdds-divider-light sdds-u-w-100"></div>
</div>
