<div
  class="sdds-toast {{ options.toastClass }}"
  [style.display]="state.value === 'inactive' ? 'none' : ''"
>
  <div class="sdds-toast-icon">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    ></svg>
  </div>

  <div class="sdds-toast-content">
    <div class="sdds-toast-header">
      <span class="sdds-toast-headline">{{ title }}</span>
      <span class="sdds-toast-dismiss"></span>
    </div>

    <div *ngIf="message" class="sdds-toast-body" [class]="options.messageClass">
      <span class="sdds-toast-subheadline">{{ message }}</span>
    </div>
  </div>

  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
