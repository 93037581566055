import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { initializeKeycloakTokenRefresh } from '../../keycloak/keycloak';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private readonly keycloak: KeycloakService) {}

  isAuthenticated(): boolean {
    return this.keycloak.getKeycloakInstance()?.authenticated ?? false;
  }

  getToken(): Promise<string> {
    return this.keycloak.getToken();
  }

  login(): void {
    this.keycloak.login();
  }

  getUserId(): string | null {
    const token = this.keycloak.getKeycloakInstance().tokenParsed;
    if (token) {
      return token['wam_user']['global'] ?? token['wam_user']['xds'];
    }

    return null;
  }

  logout(): void {
    this.keycloak.logout();
  }

  getUserInitials(): string {
    const token = this.keycloak.getKeycloakInstance().tokenParsed;

    if (!token) {
      throw new Error('Token not found');
    }

    const givenInitial = token['given_name'].charAt(0).toUpperCase();
    const familyInitial = token['family_name'].charAt(0).toUpperCase();
    return givenInitial + familyInitial;
  }

  populateUserInformation(): string | null {
    const token = this.keycloak.getKeycloakInstance().tokenParsed;
    if (token) {
      const adLogin = token['wam_user']['global'] ?? token['wam_user']['xds'];
      const usersFullName = token['name'];

      if (adLogin && usersFullName) {
        return `${usersFullName} (${adLogin})`;
      }
    }

    return null;
  }

  initializeKeycloakTokenRefresh(): void {
    initializeKeycloakTokenRefresh(this.keycloak);
  }

  getUsersRoles(): string[] {
    return this.keycloak.getUserRoles();
  }

  userIsLocationAdmin(): boolean {
    return this.keycloak.isUserInRole(environment.roles.locationAdmin);
  }

  userIsGeneralManager(): boolean {
    return this.keycloak.isUserInRole(environment.roles.generalManager);
  }

  userIsAssistantGeneralManager(): boolean {
    return this.keycloak.isUserInRole(
      environment.roles.assistantGeneralManager
    );
  }

  userIsSuperuser(): boolean {
    return this.keycloak.isUserInRole(environment.roles.superuser);
  }
}
