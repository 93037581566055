import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, finalize } from 'rxjs';

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(private spinner: NgxSpinnerService) {}
  timer: NodeJS.Timeout;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => this.spinner.show(), 2000);

    return next.handle(req).pipe(
      finalize(() => {
        this.spinner.hide();
        if (this.timer) {
          clearTimeout(this.timer);
        }
      })
    );
  }
}
