import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { StandardModalComponent } from 'src/app/core/components/modals/standard-modal/standard-modal.component';
import { Employee } from 'src/app/core/models/employee';
import { EmployeeService } from 'src/app/core/services/employee/employee.service';
import Utils from 'src/app/core/utils/utils';
import { EmployeeDropdownDataFilter } from './employee-dropdown-data-filter';

@Component({
  selector: 'app-employee-dropdown',
  templateUrl: './employee-dropdown.component.html',
  styleUrls: ['./employee-dropdown.component.scss'],
})
export class EmployeeDropdownComponent implements OnInit {
  @ViewChild(StandardModalComponent) modal: StandardModalComponent;

  _dataFilter: EmployeeDropdownDataFilter;
  @Input() set dataFilter(dataFilter: EmployeeDropdownDataFilter) {
    this._dataFilter = dataFilter;
    this.getEmployeesForLocation(
      dataFilter.locationFullName,
      dataFilter.period
    );
  }

  @Input() emptyItemText: string = '';
  @Output() selectionChanged = new EventEmitter<Employee>();
  @Output() employeesChanged = new EventEmitter<Employee[]>();

  private employees: Employee[];
  public busy: boolean = false;
  public employeesDropdownList: string;

  constructor(public employeeService: EmployeeService) {}

  ngOnInit(): void {}

  employeeSelected(event) {
    var value = event.detail.value;
    this.selectionChanged.emit(
      this.employees.find((e) => e.employeeId == value)
    );
  }

  reset() {
    const dropdown: any = document.querySelector('#employeeDropdown');
    dropdown.resetOption();
  }

  getEmployeesForLocation(locationFullName: string, date: Date) {
    this.busy = true;
    this.employeeService
      .getEmployeesInLocation(locationFullName, date)
      .subscribe({
        next: (response) => {
          this.employees = response;

          this.employeesChanged.emit(response);
          this.employeesDropdownList = Utils.ObjectListToDropdownList(
            response,
            ['employeeName', 'employeeId'],
            'employeeId',
            this.emptyItemText
          );
        },
        error: (e) => {
          this.modal.showServerError();
        },
      })
      .add(() => (this.busy = false));
  }
}
