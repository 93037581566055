import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ElementType } from 'src/app/core/models/elementype';

@Component({
  selector: 'app-pay-entry-input',
  templateUrl: './pay-entry-input.component.html',
  styleUrls: ['./pay-entry-input.component.scss'],
})
export class PayEntryInputComponent implements OnInit {
  @Input() title: string;
  @Input() value: any;
  @Input() max: number;
  @Input() type: ElementType;
  @Input() errors: string[];
  @Output() valueChange = new EventEmitter<any>();

  elementType = ElementType;

  constructor() {}

  ngOnInit(): void {}

  changed(value: any) {
    this.errors = null;
    this.valueChange.emit(value);
  }
}
