<sdds-dropdown-filter
  placeholder="Select an Option"
  label="Location"
  label-position="outside"
  [data]="locationsDropdownList"
  (selectOption)="locationSelected($event)"
  open-direction="down"
>
</sdds-dropdown-filter>

<app-standard-modal></app-standard-modal>
