<section class="login-container h-screen">
  <div class="login-panel">
    <div class="sdds-paragraph-01">
      Welcome to the<br />
      <span class="sdds-headline-03"
        >Scania Timesheets
        <span *ngIf="!isProduction" class="sdds-detail-02 sdds-text-grey-800"
          >v{{ currentApplicationVersion }}</span
        >
      </span>
    </div>
    <div class="sdds-detail-02 sdds-text-grey-800 sdds-u-mt3">
      Log into your Scania SSO account.
    </div>
    <button
      class="login-button sdds-u-mt3 sdds-btn sdds-btn-primary sdds-background-positive"
      (click)="login()"
    >
      Log In
    </button>
  </div>
  <div class="login-image"></div>
</section>
