import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LayoutComponent } from './core/components/layout/layout.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'payentry',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: {
      roles: Object.values(environment.roles),
    },
    children: [
      {
        path: 'payentry',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/pay-entry/pay-entry.module').then(
            (m) => m.PayEntryModule
          ),
      },
      {
        path: 'sicknessreasons',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/sickness-reasons/sickness-reasons.module').then(
            (m) => m.SicknessReasonsModule
          ),
      },
      {
        path: 'viewreports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/view-reports/view-reports.module').then(
            (m) => m.ViewReportsModule
          ),
      },
      {
        path: 'manageusers',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/manage-users/manage-users.module').then(
            (m) => m.ManageUsersModule
          ),
      },
      {
        path: 'systemtools',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/system-tools/system-tools.module').then(
            (m) => m.SystemToolsModule
          ),
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'variableexport',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/variable-export/variable-export.module').then(
            (m) => m.VariableExportModule
          ),
      },
      {
        path: 'tafwexport',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./features/tafw-export/tafw-export.module').then(
            (m) => m.TAFWExportModule
          ),
      },
    ],
  },
  {
    path: 'access-denied',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/access-denied/access-denied.module').then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  { path: '**', redirectTo: 'payentry', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
