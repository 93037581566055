import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastType } from '../../components/toast/sdds-toast-type';
import { Employee } from '../../models/employee';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  apiUrl = `${environment.api.url}api/Employee`;

  constructor(private http: HttpClient, private toastService: ToastrService) {}

  getEmployeesInLocation(
    locationFullName: string,
    date?: Date
  ): Observable<Employee[]> {
    this.cancelPrevious();

    let query = `${this.apiUrl}/EmployeesInLocation/${locationFullName}`;
    if (date) query = query + `/${date.toISOString()}`;
    return this.http.get<Employee[]>(query).pipe(takeUntil(this.ngUnsubscribe));
  }

  employeeExists(employees: Employee[], employee: Employee) {
    return employees?.some((x) => x.employeeId == employee?.employeeId);
  }

  showEmployeeTerminatedToast(employeeName: string) {
    this.toastService.show(
      `${employeeName} was not employed during the current selected period/date.`,
      'Employee Error',
      {
        toastClass: ToastType.ToastWarning,
      }
    );
  }

  // Cancel previous subscription and create a new one.
  cancelPrevious() {
    if (!this.ngUnsubscribe.observers) {
      this.ngUnsubscribe = new Subject<void>();
    }

    this.ngUnsubscribe.next();
  }

  // Destroy subscription
  unsubscribe() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
