<ng-container [ngSwitch]="type">
  <sdds-textfield
    *ngSwitchDefault
    ngDefaultControl
    type="number"
    title=""
    [state]="errors ? 'error' : 'Default'"
    (ngModelChange)="changed($event)"
    [(ngModel)]="value"
    appTwoDigitDecimalNumber
  >
    <span slot="sdds-prefix" *ngIf="type == elementType.Currency">£</span>
    <span slot="sdds-suffix" *ngIf="type == elementType.Decimal">Hours</span>
    <span slot="sdds-label">{{ title }}</span>
    <span slot="sdds-helper" *ngIf="errors"
      ><ng-container *ngFor="let error of errors"
        >{{ error }} <br /></ng-container
    ></span>
  </sdds-textfield>

  <ng-container *ngSwitchCase="elementType.Boolean">
    <label class="sdds-detail-05">
      {{ title }}
    </label>
    <br />
    <div class="sdds-checkbox-item sdds-u-mt1">
      <input
        class="sdds-form-input sdds-u-block"
        type="checkbox"
        (ngModelChange)="changed($event)"
        [(ngModel)]="value"
      />
    </div>
  </ng-container>
</ng-container>
