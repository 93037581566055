import { Component, Input, OnInit } from '@angular/core';
import { TypeOfMessage } from './type-of-message.enum';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  @Input() messagesType: TypeOfMessage = TypeOfMessage.Informative;
  @Input() subject: string;
  @Input() body: string;

  TypeOfMessage = TypeOfMessage;

  constructor() {}

  ngOnInit(): void {}
}
