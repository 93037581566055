import { SDDSButtonSize } from '../../../sdds/class-definitions/buttons/sdds-button-size';
import { SDDSButtonType } from '../../../sdds/class-definitions/buttons/sdds-button-type';
import { ModalSize } from '../../../sdds/class-definitions/modals/modal-size';
import { ButtonConfig } from './button-config';

export class ModalConfig {
  constructor(modalConfig?: ModalConfig) {
    this.title = modalConfig.title;
    this.message = modalConfig.message;
    this.size = modalConfig.size ?? ModalSize.small;
    this.staticBackdrop = modalConfig.staticBackdrop ?? false;
    this.trackCloseEvent = modalConfig.trackCloseEvent ?? false;
    this.confirmationButtonConfig = {
      buttonText: modalConfig.confirmationButtonConfig?.buttonText ?? 'Ok',
      buttonType:
        modalConfig.confirmationButtonConfig?.buttonType ??
        SDDSButtonType.primaryButton,
      buttonSize:
        modalConfig.confirmationButtonConfig?.buttonSize ??
        SDDSButtonSize.medium,
    };

    this.closeButtonConfig = {
      buttonText: modalConfig.closeButtonConfig?.buttonText ?? 'Close',
      buttonType:
        modalConfig.closeButtonConfig?.buttonType ??
        SDDSButtonType.secondaryButton,
      buttonSize:
        modalConfig.closeButtonConfig?.buttonSize ?? SDDSButtonSize.medium,
    };
  }

  title: string;
  message: string;
  size?: ModalSize;
  staticBackdrop?: boolean;
  trackCloseEvent?: boolean;
  confirmationButtonConfig?: ButtonConfig;
  closeButtonConfig?: ButtonConfig;
}
