import { DropdownFilterOption } from '../models/dropdownfilteroption';
import { ReportData } from '../models/report';

export default class Utils {
  static reportDataToCsvData(data: [ReportData[]]) {
    var rows = [];
    data.forEach((row) => {
      var rowValues = [];
      row.forEach((col) => {
        rowValues.push(col.formattedValue);
      });

      rows.push(rowValues);
    });

    return rows;
  }

  static ObjectListToDropdownList(
    originalList: any[],
    labelPropNames: string[],
    valuePropNames: string,
    emptyItemText: string = ''
  ) {
    let list = [];

    if (emptyItemText) {
      var option = new DropdownFilterOption();
      option.label = emptyItemText;
      option.value = undefined;
      list.push(option);
    }

    for (let index = 0; index < originalList.length; index++) {
      const listItem = originalList[index];

      var option = new DropdownFilterOption();
      option.label = this.GetLabelText(listItem, labelPropNames);
      option.value = this.GetPropertyValue(listItem, valuePropNames);
      list.push(option);
    }

    return JSON.stringify(list);
  }

  static DictionaryToDropdownList(
    originalList: any[],
    emptyItemText: string = ''
  ) {
    let list = [];

    if (emptyItemText) {
      var option = new DropdownFilterOption();
      option.label = emptyItemText;
      option.value = undefined;
      list.push(option);
    }

    for (var item in originalList) {
      var option = new DropdownFilterOption();
      option.label = originalList[item];
      option.value = item;
      list.push(option);
    }

    return JSON.stringify(list);
  }

  private static GetLabelText(listItem: any, propertyNames: string[]) {
    let labelText: string = '';
    propertyNames.forEach((p, index) => {
      if (labelText) {
        labelText += ' - ';
      }

      labelText += `${this.GetPropertyValue(listItem, p).toString().trim()}`;
    });

    return labelText;
  }

  private static GetPropertyValue(listItem: any, propertyName: string) {
    return listItem[propertyName];
  }

  public static ResetDropdown(selector) {
    const dropdown: any = document.querySelector(`#${selector}`);
    if (dropdown) dropdown.resetOption();
  }

  public static GetErrorForFieldFromResponse(response, fieldName) {
    // if the errors array is not defined it is very likely that there are issues with the server.
    if (response.error.errors == null) {
      return response.message ?? 'A server error has occurred';
    }

    return response.error.errors[fieldName];
  }
}
