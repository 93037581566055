import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCaseToWords',
  pure: false,
})
export class CamelCaseToWordsPipe implements PipeTransform {
  constructor() {}

  transform(str: string): string {
    return str
      .replace(/^[a-z]/g, (char) => ` ${char.toUpperCase()}`)
      .replace(/[A-Z]|[0-9]+/g, ' $&')
      .replace(/(?:\s+)/, (char) => '');
  }
}
