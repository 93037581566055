import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { StandardModalComponent } from 'src/app/core/components/modals/standard-modal/standard-modal.component';
import { ElementDropdownType } from 'src/app/core/enums/element-dropdown-type';
import { KPayElement } from 'src/app/core/models/kpayelement';
import { ElementService } from 'src/app/core/services/element/element.service';
import Utils from 'src/app/core/utils/utils';

@Component({
  selector: 'app-element-dropdown',
  templateUrl: './element-dropdown.component.html',
  styleUrls: ['./element-dropdown.component.scss'],
})
export class ElementDropdownComponent implements OnInit {
  @ViewChild(StandardModalComponent) modal: StandardModalComponent;
  @Output() selectionChanged = new EventEmitter<KPayElement>();
  @Input() type: ElementDropdownType = ElementDropdownType.All;

  private elements: KPayElement[];
  public elementsDropdownList: string;

  constructor(public elementService: ElementService) {}

  ngOnInit(): void {
    this.getElements();
  }

  elementSelected(event) {
    var value = event.detail.value;
    this.selectionChanged.emit(this.elements.find((e) => e.elementId == value));
  }

  getElements() {
    var getElements =
      this.type == ElementDropdownType.All
        ? this.elementService.getElements()
        : this.elementService.getTimeAwayFromWorkElements();

    getElements.subscribe({
      next: (response) => {
        this.elements = response;
        this.elementsDropdownList = this.ObjectListToDropdownList(response);
      },
      error: (e) => {
        this.modal.showServerError();
      },
    });
  }

  private ObjectListToDropdownList(response: any) {
    return Utils.ObjectListToDropdownList(
      response,
      ['name', 'code'],
      'elementId'
    );
  }
}
