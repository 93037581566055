<div class="sdds-row">
  <div
    class="sdds-col-max-12 sdds-col-xxlg-12 sdds-col-xlg-12 sdds-col-lg-12 sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 sdds-u-mb1"
  >
    <span class="sdds-detail-05">Roles</span>
  </div>
  <div
    class="sdds-col-max-3 sdds-col-xxlg-3 sdds-col-xlg-3 sdds-col-lg-4 sdds-col-md-4 sdds-col-sm-6 sdds-col-xs-8"
  >
    <div *ngFor="let role of clientRoles">
      <div class="sdds-checkbox-item">
        <input
          class="sdds-form-input"
          type="checkbox"
          (change)="role.checked = !role.checked; validateRoles()"
          [checked]="role.checked"
          [disabled]="disabled"
        />
        <label class="sdds-form-label">
          {{ role.name | camelCaseToWords }}
        </label>
      </div>
    </div>
    <span
      class="sdds-form-textfield-error sdds-textfield-helper sdds-form-textfield-error-color"
      >{{ form.get("Roles") | getError }}</span
    >
  </div>
</div>
