<div class="sdds-row sdds-u-mb2 sdds-u-mt2" [formGroup]="listBoxForm">
  <div
    class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-10 sdds-col-md-10 sdds-col-sm-10 sdds-col-xs-10"
  >
    <sdds-textfield
      size="sm"
      type="text"
      placeholder="{{ availableFilterPlaceholder }}"
      [allowPaste]="true"
      formControlName="availableSearchInput"
      ngDefaultControl
    >
      <span slot="sdds-label">{{ availableText }}</span>
    </sdds-textfield>

    <select
      class="list-box sdds-u-mb2 sdds-u-mt1 sdds-textfield-input sdds-u-w-100 sdds-u-h-auto"
      formControlName="availableListBox"
      multiple
    >
      <option
        *ngFor="
          let item of availableItems
            | arrayFilter : ['text', searchTermAvailable]
            | arraySort : ['text', 'ASC'];
          trackBy: trackByValue
        "
        [value]="item?.value"
        (dblclick)="moveAvailableItemToSelected(item)"
      >
        {{ item?.text }}
      </option>
    </select>
  </div>

  <div
    class="sdds-col-max-2 sdds-col-xxlg-2 sdds-col-xlg-2 sdds-col-lg-2 sdds-col-md-2 sdds-col-sm-2 sdds-col-xs-2"
  >
    <div class="sdds-u-textalign-center">
      <sdds-tooltip
        class="sdds-u-relative u-left-m35"
        placement="top"
        selector="#DualListMoveAllButton"
        text="Add all {{ availableText }}"
      >
      </sdds-tooltip>
      <sdds-tooltip
        class="sdds-u-relative u-left-m35"
        placement="top"
        selector="#DualListAddButton"
        text="Add {{ itemText }}"
      >
      </sdds-tooltip>
      <sdds-tooltip
        class="sdds-u-relative u-left-m35"
        placement="top"
        selector="#DualListRemoveButton"
        text="Remove {{ itemText }}"
      >
      </sdds-tooltip>
      <sdds-tooltip
        class="sdds-u-relative u-left-m35"
        placement="top"
        selector="#DualListRemoveAllButton"
        text="Remove all {{ selectedText }}"
      >
      </sdds-tooltip>
    </div>

    <div class="u-mt100">
      <div
        class="sdds-row buttonRow sdds-u-block sdds-u-textalign-center sdds-u-mt2"
      >
        <button
          id="DualListMoveAllButton"
          type="button"
          class="sdds-btn sdds-btn-secondary sdds-btn-sm"
          *ngIf="moveAllButton"
          (click)="moveAllItemsToSelected()"
        >
          <img
            class="u-top-m5 sdds-u-relative"
            src="/assets/scania-icons/24px/Chevron-DoubleRight.svg"
          />
        </button>
      </div>
      <div class="sdds-row sdds-u-block sdds-u-textalign-center sdds-u-mt2">
        <button
          id="DualListAddButton"
          type="button"
          class="sdds-btn sdds-btn-secondary sdds-btn-sm"
          (click)="moveMarkedAvailableItemsToSelected()"
        >
          <img
            class="u-top-m5 sdds-u-relative"
            src="/assets/scania-icons/24px/Chevron-Right.svg"
          />
        </button>
      </div>
      <div class="sdds-row sdds-u-block sdds-u-textalign-center sdds-u-mt2">
        <button
          id="DualListRemoveButton"
          type="button"
          class="sdds-btn sdds-btn-secondary sdds-btn-sm"
          (click)="moveMarkedSelectedItemsToAvailable()"
        >
          <img
            class="u-top-m5 sdds-u-relative"
            src="/assets/scania-icons/24px/Chevron-Left.svg"
          />
        </button>
      </div>

      <div class="sdds-row sdds-u-block sdds-u-textalign-center sdds-u-mt2">
        <div>
          <button
            id="DualListRemoveAllButton"
            type="button"
            class="sdds-btn sdds-btn-secondary sdds-btn-sm"
            *ngIf="moveAllButton"
            (click)="moveAllItemsToAvailable()"
          >
            <img
              class="u-top-m5 sdds-u-relative"
              src="/assets/scania-icons/24px/Chevron-DoubleLeft.svg"
            />
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="sdds-col-max-5 sdds-col-xxlg-5 sdds-col-xlg-5 sdds-col-lg-10 sdds-col-md-10 sdds-col-sm-10 sdds-col-xs-10"
  >
    <sdds-textfield
      size="sm"
      type="text"
      placeholder="{{ selectedFilterPlaceholder }}"
      [allowPaste]="true"
      formControlName="selectedSearchInput"
      ngDefaultControl
    >
      <span slot="sdds-label">{{ selectedText }}</span>
    </sdds-textfield>

    <select
      class="list-box sdds-u-mb2 sdds-u-mt1 sdds-textfield-input sdds-u-w-100 sdds-u-h-auto"
      formControlName="selectedListBox"
      multiple
    >
      <option
        *ngFor="
          let item of selectedItems
            | arrayFilter : ['text', searchTermSelected]
            | arraySort : ['text', 'ASC'];
          trackBy: trackByValue
        "
        [value]="item?.value"
        (dblclick)="moveSelectedItemToAvailable(item)"
      >
        {{ item?.text }}
      </option>
    </select>
  </div>
</div>
