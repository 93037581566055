import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  redirect: string = '';
  isProduction: boolean = environment.production;
  currentApplicationVersion = environment.appVersion;

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    const authenticated = this.userService.isAuthenticated();
    if (authenticated) {
      this.router.navigate(['/search']);
    }
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.redirect = params['redirect'];
    });
  }

  login() {
    this.userService.login();
  }
}
