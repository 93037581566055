import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter<Date>();
  @Input() selectedDate: Date = new Date();

  constructor() {}

  ngOnInit(): void {}

  dateSelected(event) {
    var dateValue = event.target.value;

    this.selectedDate = dateValue ? new Date(event.target.value) : undefined;
    this.selectionChanged.emit(this.selectedDate);
  }
}
